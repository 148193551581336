import { Provider } from "react-redux";
import Routes from "./routes/Routes";
import store from "./store";
import "./styles/global.css"

function App() {
  
  return (
    <div className="App">
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  )
}

export default App;
